<template>
  <div class="comment-container">
    <div class="type-title">评论( {{commentList.length}} )</div>
    <div class="comment-list-warp" v-loading="listLoading">
      <div v-for="item in commentList" :key="item.id" class="comment-item">
        <div class="comment-info">
          <div class="user-info">
            <el-avatar :size="32" icon="el-icon-user-solid"></el-avatar>
            <span class="user-name">{{ item.user_name }}</span>
          </div>
          <div class="time">
            2022-08-22 10:15:35
          </div>
        </div>
        <div class="comment-content">
          {{ item.content }}
        </div>
        <div class="comment-action">
          <el-button type="text" v-if="userData.id === item.user_id" @click.stop="handleDelete(item)"><i class="iconfont icongis_shanchu"></i>删 除</el-button>
        </div>
      </div>
      <el-empty v-if="!commentList.length" description="暂无评论"></el-empty>
    </div>
    <div v-if="isLogin" class="comment-input-warp">
      <div class="user-info-warp">
        <el-avatar :size="32" icon="el-icon-user-solid"></el-avatar>
      </div>
      <div class="input-warp">
        <el-form :ref="(el) => { formRef = el }" :model="formData" :rules="rules" @submit.native.prevent @keypress.enter="handleSubmit">
          <el-form-item label-width="0" label="" prop="content">
            <el-input
              :rows="4"
              type="textarea"
              placeholder="请输入内容"
              v-model="formData.content"
              maxlength="400"
              resize="none"
              show-word-limit
            />
          </el-form-item>
        </el-form>
        <el-button type="primary" :loading="btnLoading" @click.stop="handleSubmit">发表</el-button>
      </div>
    </div>
    <div v-else class="no-auth-tips">
      <p class="info">
        <svg width="16" height="16">
          <use xlink:href="#iconyichangxiangmu" />
        </svg>
        &nbsp;
        请登录后进行评论
      </p>
      <p class="info">
        去 &nbsp; <router-link to="/login">登录</router-link>
      </p>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import { knowledgeComment } from '@/saas-apis/konwledgeBase.js'
import { Avatar, Message, Empty, MessageBox } from 'element-ui'
export default defineComponent({
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    
  },
  components: {
    'el-avatar': Avatar,
    'el-empty': Empty
  },
  setup(props) {
    const formData = ref({
      content: ''
    })
    const rules = {
      content: [{ required: true, message: '请输入评论', trigger: 'blur' }]
    }
    const formRef = ref(null)
    const btnLoading = ref(false)
    const handleSubmit = () => {
      if(formRef.value) {
        formRef.value.validate((valid) => {
          if(!valid) return
          const reqData = {
            content: formData.value.content,
            page_id: props.data.id
          }
          btnLoading.value = true
          knowledgeComment.post(reqData).then(res => {
            if(res.data.code === 200) {
              Message.success(res.data.msg)
              formData.value.content = ''
              getCommentList()
            }
          }).catch((err) => {
            Message.error(err)
          }).finally(() => {
            btnLoading.value = false
          })
        })
      }
    }
    const handleDelete = (item) => {
      MessageBox.confirm('此操作将永久删除该评论, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonType: 'danger',
        type: 'warning',
        customClass: 'liner-message-box'
      }).then(() => {
        handleDeleteItem(item)
      }).catch(() => {
        // nothing to do     
      })
    }
    const handleDeleteItem = (item) => {
      const reqData = {
        id: item.id
      }
      knowledgeComment.delete(reqData).then(res => {
        if(res.data.code === 200) {
          Message.success(res.data.msg)
          getCommentList()
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      })
    }
    const listLoading = ref(false)
    const commentList = ref([])
    const getCommentList = () => {
      if(!props.data.id) return
      const reqData = {
        page_id: props.data.id,
      }
      listLoading.value = true
      if(window.location.pathname === '/knowledge/shower') {
        knowledgeComment.websiteGet(reqData).then(res => {
          if(res.data.code === 200) {
            commentList.value = res.data.data
          }
        }).catch((err) => {
          Message.error(err)
        }).finally(() => {
          listLoading.value = false
        })
      } else {
        knowledgeComment.get(reqData).then(res => {
          if(res.data.code === 200) {
            commentList.value = res.data.data
          }
        }).catch((err) => {
          Message.error(err)
        }).finally(() => {
          listLoading.value = false
        })
      }
    }
    const isLogin = ref(false)
    const userData = ref({})
    const getUserInfo = () => {
      try {
        const userInfo = localStorage.getItem('userInfo')
        if(userInfo) {
          userData.value = JSON.parse(userInfo)
          isLogin.value = true
        }
      } catch(err) {
        console.log(err)
      }
    }
    getUserInfo()
    watch(() => props.data.id, (n) => {
      if(n) {
        getCommentList()
      }
    }, { immediate: true })
    return {
      formData,
      formRef,
      rules,
      handleSubmit,
      btnLoading,
      listLoading,
      commentList,
      handleDelete,
      isLogin,
      userData
    }
  },
})
</script>
<style lang="less" scoped>
  .comment-container{
    width: 100%;
    height: auto;
    padding: 0 10px;
    box-sizing: border-box;
    .type-title{
      font-size: 1.2em;
      padding: 10px 0;
    }
    .comment-list-warp{
      width: 100%;
      height: auto;
      .comment-item{
        // max-width: 1052px;
        .comment-info{
          display: flex;
          align-items: center;
          height: 40px;
          justify-content: space-between;
          .user-info{
            display: flex;
            align-items: center;
            padding: 0 10px;
            .user-name{
              margin-left: 10px;
            }
          }
          .time{
            color: #999;
          }
        }
        .comment-content{
          height: auto;
          // max-width: 1052px;
          padding: 10px 0 10px 52px;
        }
        .comment-action{
          // max-width: 1052px;
          padding: 0px 0 0px 52px;
        }
      }
    }
    .comment-input-warp{
      width: 100%;
      display: flex;
      .user-info-warp{
        padding: 10px;
        box-sizing: border-box;
      }
      .input-warp{
        flex-grow: 1;
        // max-width: 1000px;
      }
    }
    .no-auth-tips{
      padding: 20px;
      box-sizing: border-box;
      .info{
        display: flex;
        padding: 10px;
      }
    }
  }
</style>
