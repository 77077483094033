<template>
	<div class="right-sider-container">
		<div class="right-sider-header">
			<div class="path">
				<template v-if="Array.isArray(current.parents) && current.parents.length">
					<span
						v-for="item in current.parents"
						@click.stop="handlePathChange(item)"
						class="path-item"
						:key="item.id"
					>
						<span class="path-item-name">{{ item.name }}</span>
						<span> /&nbsp;</span>
					</span>
				</template>
				{{ current.name }}
			</div>
			<div class="action">
				<template v-if="current && current.type === 'file'">
					<el-tooltip
						effect="dark"
						content="全屏"
					>
						<el-button
							@click.stop="hanleFull"
							type="text"
						>
							<svg
								class="action-icon"
								aria-hidden="true"
								width="18"
								height="18"
							>
								<use
									xlink:href="#iconquanping3"
									fill="#409eff"
								/>
							</svg>
						</el-button>
					</el-tooltip>
				</template>
				<el-tooltip
					effect="dark"
					content="返回"
				>
					<el-button
						@click.stop="hanleBack"
						type="text"
					>
						<svg
							class="action-icon"
							aria-hidden="true"
							width="18"
							height="18"
						>
							<use
								xlink:href="#iconzuojiantou"
								fill="#409eff"
							/>
						</svg>
					</el-button>
				</el-tooltip>
				<template v-if="current.type">
					<el-tooltip
						effect="dark"
						content="收藏"
					>
						<el-button
							@click.stop="hanleCollection"
							type="text"
						>
							<svg
								t="1661139680339"
								class="icon"
								viewBox="0 0 1024 1024"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								p-id="29877"
								width="16"
								height="16"
							>
								<path
									d="M497.536 99.328a32 32 0 0 1 39.872 9.6l3.008 4.928L657.28 350.72l261.312 38.016a32 32 0 0 1 21.44 50.24l-3.712 4.352-189.12 184.192 44.736 260.288a32 32 0 0 1-41.088 35.968l-5.312-2.24-218.944-115.008a32 32 0 0 0-24-2.304l-5.76 2.304-218.88 115.008a32 32 0 0 1-46.912-27.968l0.448-5.76 44.672-260.288-189.056-184.448a32 32 0 0 1 12.16-53.248l5.632-1.28 261.184-37.76 116.928-236.928a32 32 0 0 1 14.528-14.528z m117.248 309.888l-103.04-208.832-103.168 208.896-230.4 33.28L344.96 605.248l-39.488 229.504 161.6-84.864a96 96 0 0 1 79.744-4.352l9.536 4.352 161.472 84.864-39.36-229.568 166.656-162.432-230.336-33.536z"
									:fill="current.collect ? '#E6A23C' : '#161616'"
									p-id="29878"
								></path>
							</svg>
						</el-button>
					</el-tooltip>
				</template>
				<template v-if="!current.type && knowledgeDetails.is_edit">
					<!-- <el-tooltip effect="dark" content="管理">
            <el-button @click.stop="handleCommand('manageKonwledge')" type="text">
              <svg class="action-icon" aria-hidden="true" width="18" height="18">
                <use xlink:href="#icondaohang-shezhi" fill="#409eff" />
              </svg>
            </el-button>
          </el-tooltip> -->
					<el-popover
						placement="bottom-start"
						trigger="click"
						class="setting"
						width="20%"
						popper-class="popoverStyle"
					>
						<svg
							class="action-icon setting"
							aria-hidden="true"
							width="18"
							height="18"
							slot="reference"
						>
							<use
								xlink:href="#icondaohang-shezhi"
								fill="#409eff"
							/>
						</svg>
						<!-- 设置内容 -->
						<article>
							<section
								class="action-bar"
								slot="edit"
								@click.stop="handleCommand('manageKonwledge')"
							>
								<i class="custom-icon el-icon-edit-outline"></i>编辑
							</section>
							<section
								class="action-bar"
								slot="edit"
								@click.stop="handleCommand('dynamic')"
							>
								<i class="iconfont icondongtai custom-icon"></i>动态
							</section>
							<section
								class="action-bar"
								slot="edit"
								@click.stop="handleCommand('collaborator')"
							>
								<i class="iconfont icona-lianxiyonghutuandui custom-icon"></i>协作人员
							</section>
						</article>
					</el-popover>
				</template>
				<template v-else-if="current.type === 'word' && knowledgeDetails.is_edit">
					<el-tooltip
						effect="dark"
						content="编辑"
					>
						<el-button
							@click.stop="hanleEditor"
							type="text"
						>
							<svg
								class="action-icon"
								aria-hidden="true"
								width="18"
								height="18"
							>
								<use xlink:href="#iconbianji1" />
							</svg>
						</el-button>
					</el-tooltip>
				</template>
				<template v-else-if="current.type === 'file' && knowledgeDetails.is_edit">
					<el-tooltip
						effect="dark"
						content="下载"
					>
						<el-button
							@click.stop="hanleDown"
							type="text"
						>
							<svg
								class="action-icon"
								aria-hidden="true"
								width="18"
								height="18"
							>
								<use xlink:href="#iconwangpan-xiazai1x" />
							</svg>
						</el-button>
					</el-tooltip>
				</template>
			</div>
		</div>
		<div class="right-sider-warp">
			<KnowledgeViewer
				v-if="current && !current.type"
				:data="current"
				@change="handleChange"
			/>
			<RichTextViewer
				v-if="current && current.type === 'word'"
				:data="current"
				:text="wordText"
			/>
			<FolderViewer
				v-if="current && current.type === 'folder'"
				:data="current"
				@change="handleChange"
			/>
			<FileViewer
				v-if="current && current.type === 'file'"
				:data="current"
			/>
		</div>
	</div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import RichTextViewer from './RichTextViewer';
import KnowledgeViewer from './KnowledgeViewer.vue';
import FolderViewer from './FolderViewer.vue';
import FileViewer from './FileViewer.vue';
import Router from '@/router';
import { konwledgeCollectionAPI } from '@/saas-apis/konwledgeBase.js';
import { Message, Tooltip, Popover } from 'element-ui';
export default defineComponent({
	props: {
		current: {
			type: Object,
			default() {
				return {};
			}
		},
		knowledgeDetails: {
			type: Object,
			default() {
				return {};
			}
		}
	},
	components: {
		RichTextViewer,
		KnowledgeViewer,
		FolderViewer,
		FileViewer,
		'el-tooltip': Tooltip,
		'el-popover': Popover
	},
	setup(props, ctx) {
		const wordText = computed(() => {
			if (props.current && props.current.detail) {
				return props.current.detail.content || '';
			} else {
				return '';
			}
		});
		const hanleEditor = () => {
			Router.push({
				name: 'KnowledgeEditor',
				params: {
					id: props.current.id
				}
			});
		};
		// 收藏
		const hanleCollection = () => {
			konwledgeCollectionAPI({ page_id: props.current.id })
				.then(res => {
					if (res.data.code === 200) {
						Message.success(res.data.msg);
						ctx.emit('collectionChange', !props.current.collect);
					} else {
						Message.error(res.data.msg);
					}
				})
				.catch(err => {
					Message.error(err);
				});
		};
		const handleCommand = (command, data = null) => {
			if(command === 'manageKonwledge'){
				ctx.emit('handleCommand', command, data);
			} else {
				let data = props.current
				ctx.emit('handleCommand', command, data);
			}
		};
		const handleChange = res => {
			ctx.emit('change', res);
		};
		const handlePathChange = res => {
			ctx.emit('change', res.id);
		};
		const hanleDown = () => {
			if (props.current.detail.path) {
				let a = document.createElement('a');
				a.href = props.current.detail.path;
				a.setAttribute('target', '_blank');
				a.setAttribute('download', props.current.name);
				a.click();
				// 移除a的引用
				setTimeout(() => {
					a = null;
				}, 100);
			}
		};
		const hanleBack = () => {
			Router.back();
		};
		const hanleFull = () => {
			let url = props.current.detail.path;
			window.open(
				url.startsWith('https')
					? `https://weboffice.bimcc.net?ssl=1&furl=${url}`
					: `https://weboffice.bimcc.net?furl=${url}`
			);
		};
		return {
			wordText,
			hanleEditor,
			handleCommand,
			handleChange,
			hanleCollection,
			handlePathChange,
			hanleDown,
			hanleBack,
			hanleFull
		};
	}
});
</script>
<style lang="less" scoped>
.right-sider-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.right-sider-header {
		height: 50px;
		display: flex;
		flex-shrink: 0;
		align-items: center;
		justify-content: space-between;
		padding: 0 10px;
		.path {
			display: flex;
			font-weight: 600;
			font-size: 16px;
			.path-item {
				cursor: pointer;
				&:hover {
					.path-item-name {
						color: #409eff;
					}
				}
			}
		}
		.action {
			margin-right: 16px;
			display: flex;
			.setting {
				display: flex;
				align-items: center;
				margin: -1px 0 0 6px;
			}
		}
	}
	.right-sider-warp {
		flex-grow: 1;
		height: 10px;
		margin-top: 10px;
	}
}
.action-bar {
	width: 100%;
	height: 100%;
	padding: 6px 8px 6px 12px;
	box-sizing: border-box;
	font-size: 14px;
	border-radius: 6px;
	&:hover {
		background-color: #f7f7f7;
	}
	.custom-icon {
		margin-right: 8px;
		font-size: 16px;
	}
}
</style>
<style lang="less">
	.el-popover.popoverStyle{
		min-width: 105px;
	}
</style>
