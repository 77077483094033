<template>
  <div v-loading="konwledgeLoading" class="konwledge-details-container normalpage-container">
    <template v-if="knowledgeDetails">
      <aside class="left-sider" v-loading="treeDataLoading">
        <PageTree
          v-if="!treeDataLoading"
          :treeData="treeData" 
          v-model="activeID"
          :knowledgeDetails="knowledgeDetails"
          @change="handleChange"
          @handleCommand="handleCommand"
        />
      </aside>
      <section class="right-sider" v-loading="currentDataLoading">
        <RightSider 
          @handleCommand="handleCommand"
          :current="currentData"
          @change="handleChange"
          :knowledgeDetails="knowledgeDetails"
          @collectionChange="handleCollectionChange"
        />
      </section>
    </template>
    <el-empty style="width: 100%" v-else description="发生了一些错误，您要的页面不见了"></el-empty>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      top="15vh"
    >
      <el-form
        label-position="top"
        :model="formData"
        :rules="formRules"
        :ref="(el) => { formRef = el }"
        @submit.native.prevent=""
        @keypress.enter.prevent="handleSubmit"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="formData.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="btnLoading" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="管理知识库"
      :visible.sync="dialogVisibleKonwledge"  
      width="40%"
      :close-on-click-modal="false"
    >
      <NewKonwledge v-if="dialogVisibleKonwledge" :btnLoading="btnLoading" :data="knowledgeDetails" @submit="handleSubmitKonwledge"/>
    </el-dialog>
    <!-- 协作人员 -->
    <el-dialog
      title="协作人员"
      :visible.sync="dialogVisibleCollaborator"  
      width="40%"
      :close-on-click-modal="false"
    >
      <Collaborators v-if="dialogVisibleCollaborator" :btnLoading="btnLoading" :data="dataList" @submit="handleSubmitKonwledge"/>
    </el-dialog>
    <!-- 动态 -->
    <el-dialog
      title="动态"
      :visible.sync="dialogVisibleDynamic"  
      width="40%"
      :close-on-click-modal="false"
    >
      <Dynamic v-if="dialogVisibleDynamic" :btnLoading="btnLoading" :data="dataList" @change="handleChange"/>
    </el-dialog>
  </div>
</template>
<script>
import PageTree from './components/PageTree.vue'
import RightSider from './components/RightSider.vue'
import { defineComponent, ref, reactive, onMounted } from 'vue'
import Router from '@/router'
import Upload from '@/manage-views/views/knowledgeBase/components/upload/upload.js'
import { knowledgePage, knowledgeDetail, fileDetails, updateKnowledge } from '@/saas-apis/konwledgeBase.js'
import { Message, Empty, MessageBox } from 'element-ui'
import NewKonwledge from '@/manage-views/views/knowledgeBase/home/modal/NewKonwledge.vue'
import Collaborators from '@/manage-views/views/knowledgeBase/home/modal/Collaborators.vue'
import Dynamic from '@/manage-views/views/knowledgeBase/home/modal/Dynamic.vue'
export default defineComponent({
  components: {
    PageTree,
    RightSider,
    'el-empty': Empty,
    NewKonwledge,
    Collaborators,
    Dynamic
  },
  setup() {
    const knowledgeDetails = ref(null)
    const konwledgeLoading = ref(false)
    const getknowledgeDetails = () => {
      const reqData = {
        repository_id: Router.currentRoute.params.id
      }
      konwledgeLoading.value = true
      knowledgeDetail(reqData).then(res => {
        if(res.data.code === 200) {
          knowledgeDetails.value = res.data.data
          currentData.value = res.data.data
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        konwledgeLoading.value = false
      })
    }
    getknowledgeDetails()
    const treeData = ref([])
    const treeDataLoading = ref(false)
    const getTreeData = () => {
      const reqData = {
        repository_id: Router.currentRoute.params.id
      }
      treeDataLoading.value = true
      knowledgePage.get(reqData).then(res => {
        if(res.data.code === 200) {
          // Message.success(res.data.msg)
          treeData.value = res.data.data
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        treeDataLoading.value = false
      })
    }
    getTreeData()
    // 上传由Upload提供支持
    const upload = new Upload()
    // 这里会覆盖方法
    upload.handleSuccess = (res, calldata) => {
      if(res && res.code === 200) {
        calldata.name = res.data.filename
        calldata.detail.path = res.data.url
        btnLoading.value = true
        knowledgePage.post(calldata).then(res => {
          if(res.data.code === 200) {
            Message.success(res.data.msg)
            getTreeData()
          }
        }).catch((res) => {
          Message.error(res.data.msg)
        }).finally(() => {
          btnLoading.value = false
        })
      }
    }
    // 捕获内部触发的事件
    const dialogVisibleCollaborator = ref(false)
    const dialogVisibleDynamic = ref(false)
    const dataList = ref([])
    const handleCommand = (command, data) => {
      switch(command) {
        case 'newText': {
          formData.value = {
            repository_id: Router.currentRoute.params.id,
            name: '',
            type: 'word',
            parent_id: data ? data.id : 0,
            detail: {
              path: '',
              content: ''
            }
          }
          dialogTitle.value = '新增文本文档'
          dialogVisible.value = true
          break
        }
        case 'newFolder': {
          formData.value = {
            repository_id: Router.currentRoute.params.id,
            name: '',
            type: 'folder',
            parent_id: data ? data.id : 0,
            detail: {
              path: '',
              content: ''
            }
          }
          dialogTitle.value = '新增文件夹'
          dialogVisible.value = true
          break
        }
        case 'newFile': {
          const callData = {
            repository_id: Router.currentRoute.params.id,
            name: '',
            type: 'file',
            parent_id: data ? data.id : 0,
            detail: {
              path: '',
              content: ''
            }
          }
          upload.open(callData)
          break
        }
        case 'delete': {
          handleDelete(data)
          break
        }
        case 'rename': {
          handleRename(data)
          break
        }
        case 'manageKonwledge': {
          dialogVisibleKonwledge.value = true
          break
        }
        case 'dynamic': {
          dialogVisibleDynamic.value = true
          dataList.value = data
          break
        }
        case 'collaborator': {
          dialogVisibleCollaborator.value = true
          dataList.value = data
        }
      }
    }
    const handleDelete = (item) => {
      MessageBox.confirm(`此操作将永久删除 "${ item.name }", 是否继续?`, '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        confirmButtonType: 'danger',
        type: 'warning',
        customClass: 'liner-message-box'
      }).then(() => {
        handleDeleteItem(item)
      }).catch(() => {
        // nothing to do     
      })
    }
    const handleDeleteItem = (item) => {
      const reqData = {
        id: item.id
      }
      knowledgePage.delete(reqData).then(res => {
        if(res.data.code === 200) {
          Message.success(res.data.msg)
          getTreeData()
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      })
    }
    const handleRename = (item) => {
      formData.value = {
        id: item.id,
        name: item.name,
      }
      dialogTitle.value = '重命名'
      dialogVisible.value = true
    }
    const dialogVisible = ref(false)
    const dialogTitle = ref('')
    const formRef = ref(null)
    const formData = ref({
      name: '',
    })
    const formRules = reactive({
      name: [{ required: true, message: '不能为空', trigger: 'blur' }]
    })
    const btnLoading = ref(false)
    const handleSubmit = () => {
      if(formRef.value) {
        formRef.value.validate((valid) => {
          if(!valid) return
          btnLoading.value = true
          const REQUESTMETHOD = formData.value.id ? knowledgePage.put : knowledgePage.post
          REQUESTMETHOD(formData.value).then(res => {
            if(res.data.code === 200) {
              Message.success(res.data.msg)
              if(res.data.data.type === 'word' && !formData.value.id) {
                Router.push({
                  name: 'KnowledgeEditor',
                  params: {
                    id: res.data.data.id
                  }
                })
              } else {
                getTreeData()
              }
              dialogVisible.value = false
            }
          }).catch((res) => {
            Message.error(res.data.msg)
          }).finally(() => {
            btnLoading.value = false
          })
        })
      }
    }
    const currentData = ref(null)
    const currentDataLoading = ref(false)
    const activeID = ref('')
    // activeID change
    const handleChange = (id) => {
      activeID.value = id
      if(!id) {
        currentData.value = knowledgeDetails.value
        return
      }
      const reqData = {
        repository_id: Router.currentRoute.params.id,
        page_id: id,
      }
      currentDataLoading.value = true
      fileDetails(reqData).then(res => {
        if(res.data.code === 200) {
          currentData.value = res.data.data
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        currentDataLoading.value = false
      })
    }
    const dialogVisibleKonwledge = ref(false)
    const handleSubmitKonwledge = (res) => {
      btnLoading.value = true
      updateKnowledge(res).then(res => {
        if(res.data.code === 200) {
          Message.success(res.data.msg)
          getknowledgeDetails()
           dialogVisibleKonwledge.value = false
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
         btnLoading.value = false
      })
    }
    const handleCollectionChange = (res) => {
      currentData.value.collect = res
    }
    onMounted(() => {
      if(Router.currentRoute.query.pageId) {
        handleChange(Router.currentRoute.query.pageId)
      }
    })
    return {
      treeData,
      handleCommand,
      dialogVisible,
      formData,
      formRules,
      handleSubmit,
      handleChange,
      currentData,
      konwledgeLoading,
      treeDataLoading,
      knowledgeDetails,
      btnLoading,
      currentDataLoading,
      activeID,
      dialogTitle,
      dialogVisibleKonwledge,
      dialogVisibleDynamic,
      dialogVisibleCollaborator,
      dataList,
      handleSubmitKonwledge,
      handleCollectionChange,
      formRef
    }
  },
})
</script>
<style lang="less" scoped>
  @import "../../../css/manageAdd.less";
  .konwledge-details-container{
    width: 100%;
    height: 100%;
    display: flex;
    text-align: left;
    background: #fff;
    .left-sider{
      width: 300px;
      flex-shrink: 0;
    }
    .right-sider{
      flex-grow: 1;
      width: 10px;
      margin-left: 16px;
    }
  }
</style>

<style lang="less">
.liner-message-box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border: none;
  .el-message-box__header{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 48px;
    background: linear-gradient(90deg, #6292FF 0%, #DE93FF 84%, #CE92FF 91%, #C568FF 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 68px 0 24px;
    color: #fff;
    .el-message-box__title{
      color: #fff;
      font-size: 14px;
    }
    .el-message-box__headerbtn{
      top: 14px;
      right: 24px;
      .el-message-box__close{
        color: #fff;
          font-size: 20px;
      }
    }
  }
}
</style>
