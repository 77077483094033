<template>
  <div class="left-sider-container">
    <div class="left-sider-header">
      <div class="title" @click.stop="handleNodeClick(null)">{{ knowledgeDetails.name }}</div>
      <div class="action" v-show="knowledgeDetails.is_edit" @click.stop="">
        <i
          style="margin-right: 4px;"
          class="iconfont iconwangpan-fenxiang1x"
          @click.stop="handleShareClick" />
        <el-dropdown @command="(command) => { handleCommand(command, null) }" placement="bottom-start">
          <span class="action-button">
            <i class="iconfont iconxinzeng2 fs18" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="newText">
              <p class="action-item">
                <svg class="action-icon" aria-hidden="true" width="16" height="16">
                  <use xlink:href="#iconwenben1" />
                </svg>
                <span class="action-desc">新建文本文档</span>
              </p>
            </el-dropdown-item>
            <el-dropdown-item command="newFolder">
              <p class="action-item">
                <svg class="action-icon" aria-hidden="true" width="16" height="16">
                  <use xlink:href="#iconwenjianjia1" />
                </svg>
                <span class="action-desc">新建文件夹</span>
              </p>
            </el-dropdown-item>
            <el-dropdown-item command="newFile">
              <p class="action-item">
                <svg class="action-icon" aria-hidden="true" width="16" height="16">
                  <use xlink:href="#iconwenjian1" />
                </svg>
                <span class="action-desc">新建文件</span>
              </p>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="left-sider-warp">
      <el-tree
        :ref="(el) => { treeRef = el }"
        v-if="Array.isArray(treeData) && treeData.length"
        :data="treeData"
        default-expand-all
        :expand-on-click-node="false"
        node-key="id"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ data }">
          <div class="node-label">
            <div class="icon">
              <svg v-if="data.type === 'word' || data.type === 'folder'" class="action-icon" aria-hidden="true" width="20" height="20">
                <use v-if="data.type === 'word'" xlink:href="#iconwenben1" />
                <use v-else-if="data.type === 'folder'" xlink:href="#iconwenjianjia1" />
              </svg>
              <svg v-else class="action-icon" aria-hidden="true" width="20" height="20">
                <use :xlink:href="getFileSvgIcon(data)" />
              </svg>
            </div>
            <div class="label">
              {{ data.name }}
            </div>
          </div>
          <p class="action" v-show="knowledgeDetails.is_edit" @click.stop="">
            <el-dropdown @command="(command) => { handleCommand(command, data) }" placement="bottom-start">
              <span class="action-button">
                <i class="el-icon-more" style="padding: 10px"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="newText" v-if="data.type === 'folder'">
                  <p class="action-item">
                    <svg class="action-icon" aria-hidden="true" width="16" height="16">
                      <use xlink:href="#iconwenben1" />
                    </svg>
                    <span class="action-desc">新建文本文档</span>
                  </p>
                </el-dropdown-item>
                <el-dropdown-item command="newFolder" v-if="data.type === 'folder'">
                  <p class="action-item">
                    <svg class="action-icon" aria-hidden="true" width="16" height="16">
                      <use xlink:href="#iconwenjianjia1" />
                    </svg>
                    <span class="action-desc">新建文件夹</span>
                  </p>
                </el-dropdown-item>
                <el-dropdown-item command="newFile" v-if="data.type === 'folder'">
                  <p class="action-item">
                    <svg class="action-icon" aria-hidden="true" width="16" height="16">
                      <use xlink:href="#iconwenjian1" />
                    </svg>
                    <span class="action-desc">新建文件</span>
                  </p>
                </el-dropdown-item>
                <el-dropdown-item command="delete">
                  <p class="action-item">
                    <svg class="action-icon" aria-hidden="true" width="16" height="16">
                      <use xlink:href="#iconwangpan-cuohao1x" />
                    </svg>
                    <span class="action-desc">删除</span>
                  </p>
                </el-dropdown-item>
                <el-dropdown-item command="rename">
                  <p class="action-item">
                    <svg class="action-icon" aria-hidden="true" width="16" height="16">
                      <use xlink:href="#iconbianji1" />
                    </svg>
                    <span class="action-desc">重命名</span>
                  </p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </p>
        </div>
      </el-tree>
      <el-empty v-else description="暂无内容"></el-empty>
    </div>
    <ShareFile
			ref="department"
			:show="showDialog"
      :data="treeData"
			@close="close"
			@sure="sure"></ShareFile>
      <el-dialog
    title="分享"
    :visible.sync="showShareDialog"
    width="500px"
    :close-on-click-modal="false"
    >
    <ShareDialog v-if="showShareDialog" :infoType="1" :file_id="filedIds" :shareId='knowledgeDetails.id' :shareType="2" @close="showShareDialog=false"></ShareDialog>
  </el-dialog>
  </div>
</template>
<script>
import { defineComponent, ref, watch, onMounted } from 'vue'
import { Dropdown, DropdownMenu, DropdownItem, Empty, Tree } from 'element-ui'
import ShareFile from './ShareFile.vue'
import ShareDialog from '@/manage-views/views/knowledgeBase/home/components/dialog.vue';
export default defineComponent({
  components: {
    'el-dropdown': Dropdown,
    'el-dropdown-menu': DropdownMenu,
    'el-dropdown-item': DropdownItem,
    'el-empty': Empty,
    'el-tree': Tree,
    ShareFile,
    ShareDialog
  },
  props: {
    knowledgeDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    treeData: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  setup(props, ctx) {
    const handleCommand = (command, data) => {
      ctx.emit('handleCommand', command, data)
    }
    const treeRef = ref(null)
    const handleNodeClick = (data) => {
      if(data === null) {
        treeRef.value?.setCurrentKey(null)
        ctx.emit('input', '')
        ctx.emit('change', null)
      } else {
        ctx.emit('input', data.id)
        ctx.emit('change', data.id)
      }
    }
    const fileTypeHash = {
      'bmp': '#iconzhaopian1x',
      'jpg': '#iconzhaopian1x',
      'png': '#iconzhaopian1x',
      'gif': '#iconzhaopian1x',
      'jpeg': '#iconzhaopian1x',
      'cdr': '#iconzhaopian1x',
      'psd': '#iconzhaopian1x',
      'doc': '#iconword1x',
      'docx': '#iconword1x',
      'pdf': '#iconpdf1x',
      'ppt': '#iconppt1x',
      'pptx': '#iconppt1x',
      'xlsx': '#iconexcle1x',
      'xls': '#iconexcle1x',
      'txt': '#icontxt1x',
      'mp3': '#iconyinle',
      'wav': '#iconyinle',
      'mp4': '#iconzhihuigongdi',
      'webm': '#iconzhihuigongdi',
      'ogg': '#iconzhihuigongdi',
    }
    const getFileSvgIcon = (data) => {
      if(!data.details) return
      const arr = data.details?.path?.split('.') || ['', '']
      const pre = arr[arr.length - 1]
      return fileTypeHash[pre.toLocaleLowerCase()] || '#iconother1x'
    }
    const showDialog = ref(false)
    const handleShareClick = () =>{
      showDialog.value = true
    }
    const close =()=>{
      showDialog.value = false
    }
    const filedIds = ref(null)
    const showShareDialog = ref(false)
    const sure =(ids)=>{
      filedIds.value = ids
      showShareDialog.value = true
      showDialog.value = false
    }
    watch(() => props.value, (n)=> {
      if(n && treeRef.value) {
        treeRef.value?.setCurrentKey(n)
      }
    })
    onMounted(() => {
      if(props.value && treeRef.value) {
        treeRef.value?.setCurrentKey(props.value)
      }
    })
    return {
      handleCommand,
      handleNodeClick,
      getFileSvgIcon,
      treeRef,
      handleShareClick,
      close,
      sure,
      showDialog,
      filedIds,
      showShareDialog
    }
  },
})
</script>
<style lang="less" scoped>

@activeColor: #409eff;
.fs18{
  font-size: 18px;
}
  .left-sider-container{
    width: 100%;
    height: 100%;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    .left-sider-header{
      height: 40px;
      padding: 0 16px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      .title{
        flex-grow: 1;
        width: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        cursor: pointer;
      }
      .action{
        padding: 0 10px;
        flex-shrink: 0;
        cursor: pointer;
        .action-button{
          // color: @activeColor; 
          font-size: 20px;
        }
      }
    }
    .left-sider-warp{
      flex-grow: 1;
      height: 10px;
      overflow-y: auto;
      :deep(.el-tree-node__expand-icon) {
        font-size: 18px;
      }
      :deep(.el-tree-node__content) {
        width: 100%;
        height: 40px;
        box-sizing: border-box;
      }
      :deep(.is-current) {
        & > .el-tree-node__content > .custom-tree-node > .node-label{
          color: @activeColor;
        }
      }
      .custom-tree-node{
        flex-grow: 1;
        width: 10px;
        height: 100%;
        display: flex;
        .node-label{
          height: 100%;
          flex-grow: 1;
          width: 10px;
          display: flex;
          align-items: center;
          .icon{
            flex-shrink: 0;
            margin-right: 6px;
          }
          .label{
            padding: 3px 0;
            flex-grow: 1;
            width: 10px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .action{
          padding: 0 10px;
          flex-shrink: 0;
          visibility: hidden;
        }
        &:hover{
          .action{
            visibility: visible;
          }
        }
      }
    }
  }
  :deep(.action-item) {
    width: 120px;
    display: flex;
    align-items: center;
    .action-icon{
      margin-right: 8px;
      flex-shrink: 0;
    }
    .action-desc{
      flex-grow: 1;
      width: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>