import { getToken } from '@/utils/tools';
// import { baseUrl } from '@/apis/http/request.js' // 全局暂无相关配置
import { Message } from 'element-ui'
import { defineComponent, ref } from 'vue'
import Vue from 'vue'
import { Progress  } from 'element-ui'
import './upload.less'

var instance = null
// 上传配置
const uploadCongig = {
  action: 'https://saas-api.bimcc.net/' + 'api/mapi?__method_name__=saasFile',
  data: { token: getToken() },
  headers: {
    metadatatoken: process.env.VUE_APP_METATOKEN,
    bimcctoken: getToken()
  },
  method: 'post',
  fileName: 'file',
}

const getError = (action, option, xhr) => {
  let msg
  if (xhr.response) {
    msg = `${xhr.response.error || xhr.response}`
  } else if (xhr.responseText) {
    msg = `${xhr.responseText}`
  } else {
    msg = `fail to ${option.method} ${action} ${xhr.status}`
  }
  const err = new Error(msg)
  err.status = xhr.status
  err.method = option.method
  err.url = action
  return err
}

const getBody = (xhr) => {
  const text = xhr.responseText || xhr.response
  if (!text) {
    return text
  }
  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}
const fileTypeHash = {
  'bmp': '#iconzhaopian1x',
  'jpg': '#iconzhaopian1x',
  'png': '#iconzhaopian1x',
  'gif': '#iconzhaopian1x',
  'jpeg': '#iconzhaopian1x',
  'cdr': '#iconzhaopian1x',
  'psd': '#iconzhaopian1x',
  'doc': '#iconword1x',
  'docx': '#iconword1x',
  'pdf': '#iconpdf1x',
  'ppt': '#iconppt1x',
  'pptx': '#iconppt1x',
  'xlsx': '#iconexcle1x',
  'xls': '#iconexcle1x',
  'txt': '#icontxt1x',
  'mp3': '#iconyinle',
  'wav': '#iconyinle',
  'mp4': '#iconzhihuigongdi',
  'webm': '#iconzhihuigongdi',
  'ogg': '#iconzhihuigongdi',
}
const getFileSvgIcon = (data) => {
  const arr = data.name.split('.')
  const pre = arr[arr.length - 1]
  return fileTypeHash[pre.toLocaleLowerCase()] || '#iconother1x'
}

const hasOwnProperty = Object.prototype.hasOwnProperty
const hasOwn = (val, key) => hasOwnProperty.call(val, key)

// 单例
class Upload {
  constructor() {
    if(instance) {
      return instance
    } else {
      instance = this
    }
    this.init()
  }
  init() {
    this.inputDom = document.createElement('input')
    this.inputDom.type = 'file'
    this.inputDom.style.visibility = 'hidden'
    document.body.appendChild(this.inputDom)
    this.fileList = []
    this.fileViewVisable = 'visible' // ''
  }
  open(callData) {
    this.inputDom.onchange = (res) => { this.handleFileChange(res, callData) }
    this.inputDom.click()
  }
  // 上传浮窗渲染
  render() {
    if(!this.renderTemplate) {
      const that = this
      this.renderTemplate = defineComponent({
        components: {
          Progress
        },
        setup() {
          const data = ref(that.fileList)
          const visible = ref(that.fileViewVisable)
          return {
            data,
            visible
          }
        },
        render(h) {
          return h('div', { 
            class: 'file-upload-container',
            style: {
              visibility: this.visible
            }
          }, [
            h('div', {
              class: 'file-upload-header'
            }, [
              h('div', {
                class: 'title'
              }, '上传列表'),
              h('i', {
                class: 'iconfont icongis_guanbi close-icon',
                on: {
                  click: () => { this.visible = 'hidden' }
                },
                onClick: () => { this.visible = 'hidden' }
              })
            ]),
            h('div', {
              class: 'file-upload-list'
            }, this.data.map(el => {
              return h('div', {
                class: 'file-upload-item'
              }, [
                h('div', {
                  class: 'item-info'
                }, [
                  h('div', {
                    class: 'info'
                  }, [
                    h('div', {
                      class: 'file-icon'
                    }, [
                      h('svg',{
                        style: {
                          width: '20px',
                          height: '20px'
                        }
                      }, [
                        h('use', {
                          attrs: {
                            'xlink:href': getFileSvgIcon(el)
                          }
                        })
                      ])
                    ]),
                    h('div', {
                      class: 'name'
                    }, el.name)
                  ]),
                  h('div', {
                    class: 'staus'
                  }, el.statusName)
                ]),
                h('div', {
                  class: 'item-progress'
                }, [
                  h('Progress', {
                    props: {
                      percentage: el.percentage,
                      status: el.percentageStatus,
                      textInside: true,
                      strokeWidth: 16
                    }
                  })
                ])
              ])
            }))
          ])
        }
      })
      const T = Vue.extend(this.renderTemplate)
      this.P = new T().$mount()
      document.body.appendChild(this.P.$el)
    } else {
      if(this.P) {
        // 老的v2写法
        this.P.$data.visible = 'visible'
        // 新的v2写法
        this.P.visible = 'visible'
      }
    }
  }
  handleFileChange(res, callData) {
    if(this.inputDom.files && this.inputDom.files.length) {
      const data = {
        name: this.inputDom.files[0].name,
        size: this.inputDom.files[0].size,
        type: this.inputDom.files[0].type,
        status: 0,
        statusName: '等待',
        percentage: 0,
        percentageStatus: 'warning',
        file: this.inputDom.files[0]
      }
      this.fileList.push(data)
      this.render()
      this.upload(data, callData)
    }
    this.inputDom.value = ''
  }
  handleProcess() {
    // 外部去覆盖的方法
  }
  onProgress(e) {
    this.handleProcess(e)
  }
  handleError() {
    // 外部去覆盖的方法
  }
  onError(err) {
    Message.error(err)
    this.handleError(err)
  }
  handleSuccess() {
    // 外部去覆盖的方法
  }
  onSuccess(res, callData) {
    this.handleSuccess(res, callData)
  }
  upload(data, callData) {
    // 上传
    const xhr = new XMLHttpRequest()
    const action = uploadCongig.action
    if (xhr.upload) {
      xhr.upload.onprogress = (e) => {
        if (e.total > 0) {
          e.percent = e.loaded / e.total * 100
        }
        data.percentage = Number((e.loaded / e.total * 100).toFixed(2))
        data.statusName = "上传中"
        data.status = 1
        this.onProgress(e)
      }
    }
    const formData = new FormData()
    if (uploadCongig.data) {
      Object.keys(uploadCongig.data).forEach((key) => {
        formData.append(key, uploadCongig.data[key])
      })
    }
    formData.append(uploadCongig.fileName, data.file, data.file.name)
    xhr.onerror = () => {
      this.onError(getError(action, uploadCongig, xhr))
      data.statusName = "失败"
      data.percentageStatus = 'exception'
      data.status = -1
    }
    xhr.onload = () => {
      if (xhr.status < 200 || xhr.status >= 300) {
        data.statusName = "失败"
        data.percentageStatus = 'exception'
        data.status = -1
        return this.onError(getError(action, uploadCongig, xhr))
      }
      const resdata = getBody(xhr)
      if(resdata.code === 200) {
        data.statusName = "上传成功"
        data.percentageStatus = 'success'
        data.status = 2
        this.onSuccess(resdata, callData)
      } else {
        data.statusName = "失败"
        data.percentageStatus = 'exception'
        data.status = -1
        Message.error(resdata.msg)
      }
    }
    xhr.open(uploadCongig.method, action, true)
    if (uploadCongig.withCredentials && 'withCredentials' in xhr) {
      xhr.withCredentials = true
    }
    const headers = uploadCongig.headers || {}
    for (const item in headers) {
      if (hasOwn(headers, item) && headers[item] !== null) {
        xhr.setRequestHeader(item, headers[item])
      }
    }
    xhr.send(formData)
    return xhr
  }
}

export default Upload
