<template>
  <div class="rich-text-viewer-container">
    <div class="rich-text-warp">
      <Shower :value="text" />
    </div>
    <div class="file-info-warp">
      <div class="item">
        <i class="iconfont icona-wodeyonghufill" />
        {{ data.user_name }}
      </div>
      <div class="item">
        <i class="iconfont iconyulan" />
        {{ data.view }}
      </div>
      <div class="item">
        <i class="iconfont iconshijian" />
        {{ data.updated_at }}
      </div>
      <div class="item">
        <el-popover
          placement="right"
          width="400"
          trigger="click">
          <el-table :data="data.record" max-height="400px" :show-header="false">
            <el-table-column width="50" type="index"></el-table-column>
            <el-table-column width="100" property="user_name"></el-table-column>
            <el-table-column width="100" property="name" label="姓名">
              <template #default="{ row }">
                {{ operationTypeHash[row.operation].label }}
              </template>
            </el-table-column>
            <el-table-column width="150" property="created_at" label="地址"></el-table-column>
          </el-table>
          <span slot="reference"><i class="iconfont iconbimgis_shuxing" />操作记录 ( {{ data.record.length }} )</span>
        </el-popover>
      </div>
    </div>
    <div class="dynamic-warp">
      <Comment :data="data" /> 
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import Shower from '@/manage-views/views/knowledgeBase/components/richText/Shower.vue'
import Comment from '@/manage-views/views/knowledgeBase/components/comment/index.vue'
import { Empty, Popover } from 'element-ui'
export default defineComponent({
  props: {
    text: {
      type: String,
      default: ''
    },
     data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    Shower,
    'el-empty': Empty,
    'el-popover': Popover,
    Comment
  },
  setup() {
    const operationTypeHash = {
      show: {
        icon: 'iconbimgis_xianshi',
        label: '查看'
      },
      add: {
        icon: 'iconxinzeng1',
        label: '新增'
      },
      edit: {
        icon: 'iconbianji1',
        label: '编辑'
      },
    }
    return {
      operationTypeHash
    }
  },
})
</script>
<style lang="less" scoped>
@fileViewHeight: 70vh;
.rich-text-viewer-container{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .rich-text-warp{
    height: @fileViewHeight;
  }
  .file-info-warp{
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    .item{
      flex-basis: 200px;
    }
  }
  .dynamic-warp{
    margin-top: 12px;
    border-top: 1px solid #f1f1f1;
  }
}
</style>