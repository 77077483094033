<template>
  <div class="knowledge-viewer-container">
    <div class="info-warp">
      <div class="info">
        <div class="cover" :style="`background-image: url(${Array.isArray(data.cover) && data.cover.length ? data.cover[0].url : ''})`">
          <svg t="1661140683420" class="fl-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="30027" width="24" height="24"><path d="M832 96a64 64 0 0 1 64 64v744.32a32 32 0 0 1-48.128 27.584L512 736l-335.872 195.904A32 32 0 0 1 128 904.32V160a64 64 0 0 1 64-64h640z m0 64H192v688.576l303.872-177.28a32 32 0 0 1 32.256 0L832 848.64V160zM672 320v64h-320V320h320z" fill="#fff" p-id="30028"></path></svg>
        </div>
        <div class="text-info">
          <p class="name">{{ data.name }}</p>
          <p class="desc">{{ data.desc }}</p>
        </div>
      </div>
    </div>
    <!-- <div class="assistant-warp">
      <div class="type-title">协作人员 ( {{ data?.users?.length || 0 }} )</div>
      <div class="users-warp" v-if="Array.isArray(data.users) && data.users.length">
        <div class="user-item" v-for="(item, index) in data.users" :key="index">
          <div class="user-info">
            <el-avatar :size="40">{{ item.name[0] }}</el-avatar>
            <p class="user-name">{{ item.name }}</p>
          </div>
        </div>
      </div>
      <el-empty v-else description="暂无内容" />
    </div> -->
    <!-- <div class="dynamic-warp" v-loading="dynamicLoading">
      <div class="type-title">动态 ( {{ total }} )</div>
      <el-table
        v-if="Array.isArray(dynamicList) && dynamicList.length"
        :data="dynamicList"
        :show-header="false"
        @row-click="handleRowClick"
      >
        <el-table-column
        >
          <template #default="{ row }">
            <div class="file-name-warp">
              <svg v-if="row.page_type === 'word' || row.page_type === 'folder'" class="flie-icon" aria-hidden="true" width="20" height="20">
                <use v-if="row.page_type === 'word'" xlink:href="#iconwenben1" />
                <use v-else-if="row.page_type === 'folder'" xlink:href="#iconwenjianjia1" />
              </svg>
              <svg v-else class="flie-icon" aria-hidden="true" width="20" height="20">
                <use :xlink:href="getFileSvgIcon(row)" />
              </svg>
              <span class="file-name"> {{ row.page_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
        >
          <template #default="{ row }">
            <div class="operation-warp">
              <i class="iconfont" :class="operationTypeHash[row.operation].icon"></i>
              <span class="operation-span">{{ operationTypeHash[row.operation].label }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
        >
          <template #default="{ row }">
            {{ row.user_name }}
          </template>
        </el-table-column>
        <el-table-column
          width="180"
        >
          <template #default="{ row }">
            {{ row.created_at }}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-warp" v-if="Array.isArray(dynamicList) && dynamicList.length">
        <el-pagination
        :current-page="currentPage"
        @current-change="handleCurrnetChange"
        :page-size="15"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
      </div>
      <el-empty v-else description="暂无内容" />
    </div> -->
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { Empty, Message, Avatar } from 'element-ui'
import { getKonwledgeDynamic } from '@/saas-apis/konwledgeBase.js'
export default defineComponent({
  components: {
    'el-empty': Empty,
    'el-avatar': Avatar
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  setup(props, ctx) {
    const currentPage = ref(1)
    const total = ref(0)
    const dynamicLoading = ref(false)
    const dynamicList = ref([])
    const getDynamicList = () => {
      const reqData = {
        repository_id: props.data.id,
        page: currentPage.value,
        pageSize: 15
      }
      dynamicLoading.value = true
      getKonwledgeDynamic(reqData).then(res => {
        if(res.data.code === 200) {
          dynamicList.value = res.data.data.data
          total.value = res.data.data.total
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        dynamicLoading.value = false
      })
    }
    getDynamicList()
    const handleCurrnetChange = (v) => {
      currentPage.value = v
      getDynamicList()
    }
    const fileTypeHash = {
      'bmp': '#iconzhaopian1x',
      'jpg': '#iconzhaopian1x',
      'png': '#iconzhaopian1x',
      'gif': '#iconzhaopian1x',
      'jpeg': '#iconzhaopian1x',
      'cdr': '#iconzhaopian1x',
      'psd': '#iconzhaopian1x',
      'doc': '#iconword1x',
      'docx': '#iconword1x',
      'pdf': '#iconpdf1x',
      'ppt': '#iconppt1x',
      'pptx': '#iconppt1x',
      'xlsx': '#iconexcle1x',
      'xls': '#iconexcle1x',
      'txt': '#icontxt1x',
      'mp3': '#iconyinle',
      'wav': '#iconyinle',
      'mp4': '#iconzhihuigongdi',
      'webm': '#iconzhihuigongdi',
      'ogg': '#iconzhihuigongdi',
    }
    const getFileSvgIcon = (data) => {
      if(!data.page_path) return 
      const arr = data.page_path.split('.')
      const pre = arr[arr.length - 1]
      return fileTypeHash[pre.toLocaleLowerCase()] || '#iconother1x'
    }
    const handleRowClick = (row) => {
      ctx.emit('change', row.page_id)
    }
    const operationTypeHash = {
      show: {
        icon: 'iconbimgis_xianshi',
        label: '查看'
      },
      add: {
        icon: 'iconxinzeng1',
        label: '新增'
      },
      edit: {
        icon: 'iconbianji1',
        label: '编辑'
      },
    }
    return {
      dynamicList,
      dynamicLoading,
      getFileSvgIcon,
      handleRowClick,
      currentPage,
      total,
      handleCurrnetChange,
      operationTypeHash
    }
  },
})
</script>
<style lang="less" scoped>
.knowledge-viewer-container{
  width: 100%;
  height: 100%;
  overflow: auto;
  .info-warp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
    box-sizing: border-box;
    .info{
      flex-grow: 1;
      width: 10px;
      display: flex;
      align-items: center;
      .cover{
        width: 300px;
        height: 200px;
        border-radius: 4px;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        .fl-icon{
          position: absolute;
          top: 10px;
          left: 10px;
        }
      }
      .text-info{
        margin-left: 20px;
        height: 200px;
        padding: 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .name{
          font-size: 1.5em;
          font-weight: 600;
        }
        .desc{
          margin-top: 24px;
          color: #999;
          width: 400px;
        }
      }
    }
  }
  // .assistant-warp{
  //   margin-top: 12px;
  //   border-top: 1px solid #f1f1f1;
  //   .type-title{
  //     padding: 10px;
  //     font-size: 1.5em;
  //   }
  //   .users-warp{
  //     display: flex;
  //     flex-wrap: wrap;
  //     .user-item{
  //       flex-basis: 100px;
  //       height: 100px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       .user-info{
  //         text-align: center;
  //         .user-name{
  //           margin-top: 5px;
  //           text-align: center;
  //           overflow: hidden;
  //           text-overflow: ellipsis;
  //           white-space: nowrap;
  //         }
  //       }
  //     }
  //   }
  // }
  // .dynamic-warp{
  //   margin-top: 12px;
  //   border-top: 1px solid #f1f1f1;
  //   .type-title{
  //     padding: 10px;
  //     font-size: 1.5em;
  //   }
  //   .file-name-warp{
  //     display: flex;
  //     align-items: center;
  //     .file-name{
  //       margin-left: 10px;
  //     }
  //   }
  //   .operation-warp{
  //     display: flex;
  //     align-items: center;
  //     .operation-span{
  //       margin-left: 10px;
  //     }
  //   }
  //   .pagination-warp{
  //     padding: 10px;
  //     box-sizing: border-box;
  //     display: flex;
  //     justify-content: flex-end;
  //   }
  // }
}
</style>