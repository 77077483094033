<template>
  <div class="folder-viewer-container">
    <div class="info-warp">
      <div class="info">
        <div class="cover">
          <svg aria-hidden="true" width="200" height="200">
            <use xlink:href="#iconwenjianjia1" />
          </svg>
        </div>
        <div class="text-info">
          <p class="name">{{ data.name }}</p>
        </div>
      </div>
      <div>

      </div>
    </div>
    <div class="dynamic-warp" v-loading="dynamicLoading">
      <div class="type-title">动态 ( {{ total }} )</div>
      <el-table
        v-if="Array.isArray(dynamicList) && dynamicList.length"
        :data="dynamicList"
        :show-header="false"
        @row-click="handleRowClick"
      >
        <el-table-column
        >
          <template #default="{ row }">
            <div class="file-name-warp">
              <svg v-if="row.page_type === 'word' || row.page_type === 'folder'" class="flie-icon" aria-hidden="true" width="20" height="20">
                <use v-if="row.page_type === 'word'" xlink:href="#iconwenben1" />
                <use v-else-if="row.page_type === 'folder'" xlink:href="#iconwenjianjia1" />
              </svg>
              <svg v-else class="flie-icon" aria-hidden="true" width="20" height="20">
                <use :xlink:href="getFileSvgIcon(row)" />
              </svg>
              <span class="file-name"> {{ row.page_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
        >
          <template #default="{ row }">
            <div class="operation-warp">
              <i class="iconfont" :class="operationTypeHash[row.operation].icon"></i>
              <span class="operation-span">{{ operationTypeHash[row.operation].label }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="180"
        >
          <template #default="{ row }">
            {{ row.user_name }}
          </template>
        </el-table-column>
        <el-table-column
          width="180"
        >
          <template #default="{ row }">
            {{ row.created_at }}
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-warp" v-if="Array.isArray(dynamicList) && dynamicList.length">
        <el-pagination
        :current-page="currentPage"
        @current-change="handleCurrnetChange"
        :page-size="15"
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
      </div>
      <el-empty v-else description="暂无内容" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue'
import { Empty, Message } from 'element-ui'
import { getFileDynamic } from '@/saas-apis/konwledgeBase.js'
export default defineComponent({
  components: {
    'el-empty': Empty
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  setup(props, ctx) {
    const currentPage = ref(1)
    const total = ref(0)
    const dynamicLoading = ref(false)
    const dynamicList = ref([])
    const getDynamicList = () => {
      const reqData = {
        page_id: props.data.id,
        page: currentPage.value,
        pageSize: 15
      }
      dynamicLoading.value = true
      getFileDynamic(reqData).then(res => {
        if(res.data.code === 200) {
          dynamicList.value = res.data.data.data
          total.value = res.data.data.total
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        dynamicLoading.value = false
      })
    }
    getDynamicList()
    const handleCurrnetChange = (v) => {
      currentPage.value = v
      getDynamicList()
    }
    const fileTypeHash = {
      'bmp': '#iconzhaopian1x',
      'jpg': '#iconzhaopian1x',
      'png': '#iconzhaopian1x',
      'gif': '#iconzhaopian1x',
      'jpeg': '#iconzhaopian1x',
      'cdr': '#iconzhaopian1x',
      'psd': '#iconzhaopian1x',
      'doc': '#iconword1x',
      'docx': '#iconword1x',
      'pdf': '#iconpdf1x',
      'ppt': '#iconppt1x',
      'pptx': '#iconppt1x',
      'xlsx': '#iconexcle1x',
      'xls': '#iconexcle1x',
      'txt': '#icontxt1x',
      'mp3': '#iconyinle',
      'wav': '#iconyinle',
      'mp4': '#iconzhihuigongdi',
      'webm': '#iconzhihuigongdi',
      'ogg': '#iconzhihuigongdi'
    }
    const getFileSvgIcon = (data) => {
      if(!data.page_path) return 
      const arr = data.page_path.split('.')
      const pre = arr[arr.length - 1]
      return fileTypeHash[pre.toLocaleLowerCase()] || '#iconother1x'
    }
    const handleRowClick = (row) => {
      ctx.emit('change', row.page_id)
    }
    const operationTypeHash = {
      show: {
        icon: 'iconbimgis_xianshi',
        label: '查看'
      },
      add: {
        icon: 'iconxinzeng1',
        label: '新增'
      },
      edit: {
        icon: 'iconbianji1',
        label: '编辑'
      }
    }
    return {
      currentPage,
      total,
      dynamicLoading,
      dynamicList,
      handleCurrnetChange,
      getFileSvgIcon,
      handleRowClick,
      operationTypeHash
    }
  },
})
</script>
<style lang="less" scoped>
.folder-viewer-container{
  width: 100%;
  height: 100%;
  overflow: auto;
  .info-warp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info{
      flex-grow: 1;
      width: 10px;
      display: flex;
      align-items: center;
      .cover{
        width: 200px;
        height: 200px;
        border-radius: 4px;
      }
      .text-info{
        margin-left: 20px;
        height: 140px;
        padding: 20px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .name{
          font-size: 1.2em;
          font-weight: 600;
        }
      }
    }
  }
  .dynamic-warp{
    margin-top: 12px;
    border-top: 1px solid #f1f1f1;
    .type-title{
      padding: 10px;
      font-size: 1.5em;
    }
    .operation-warp{
      display: flex;
      align-items: center;
      .operation-span{
        margin-left: 10px;
      }
    }
    .pagination-warp{
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>