<template>
  <div class="assistant-warp">
    <div class="type-title">协作人员 ( {{ data?.users?.length || 0 }} )</div>
    <div
      class="users-warp"
      v-if="Array.isArray(data.users) && data.users.length"
    >
      <div class="user-item" v-for="(item, index) in data.users" :key="index">
        <div class="user-info">
          <el-avatar :size="40">{{ item.name[0] }}</el-avatar>
          <p class="user-name">{{ item.name }}</p>
        </div>
      </div>
    </div>
    <el-empty v-else description="暂无内容" />
  </div>
</template>
<script>
import { Empty, Avatar } from 'element-ui'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    'el-empty': Empty,
    'el-avatar': Avatar
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    btnLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    return {
      
    };
  },
});
</script>
<style lang="less" scoped>
.assistant-warp {
  margin-top: 12px;
  border-top: 1px solid #f1f1f1;
  .type-title {
    padding: 10px;
    font-size: 1.5em;
  }
  .users-warp {
    display: flex;
    flex-wrap: wrap;
    .user-item {
      flex-basis: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      .user-info {
        text-align: center;
        .user-name {
          margin-top: 5px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
